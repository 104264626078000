import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import ChineseLayout from "../../../components/ChineseLayout"
// import Inactivity from "../../../components/Inactivity"

// Coming soon structure

class BabyDev extends React.Component {
  componentDidMount() {
    // Inactivity()
  }

  render() {
    return (
      <ChineseLayout>
        <Helmet>
          <title>Meet MJN - 探索宝宝成长轨迹</title>
          <body className="coming-soon" />
        </Helmet>
        <main role="main" className="coming-soon">
          <section className="main-item">
            <span className="main-item__title">探索宝宝成长轨迹</span>
            <span className="main-item__title -sub-title">即将呈现</span>
            <nav className="breadcrumb -white" role="navigation">
              <Link to="/zh/event-experience" title="史基浦机场活动体验">
                <i className="fas fa-arrow-left"></i>
                史基浦机场活动体验
              </Link>
            </nav>
          </section>
        </main>
      </ChineseLayout>
    )
  }
}

export default BabyDev